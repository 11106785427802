// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//dev server
export const environment = {
  production: true,
  env: 'local',
  //api: 'https://uatapi.boomcaster.com/v1/api/',
  api: 'https://dev-v2-api.boomcaster.com/v1/api/',
  testConferenceId: '3898ed9a-9cf4-42ae-b820-531b7010c026',
  localHostUrl: 'http://127.0.0.1:3000/',
  isChimeWebRtc: true,
  aws: {
    region: 'us-east-1',
    identityPoolId: 'us-east-1:24e83721-06d6-4d98-9929-e8a5b1412efa',
    s3: {
      bucket: 'boomcaster-dev',
      apiVersion: '2006-03-01',
      region: 'us-east-1',
      isAccelerate: true
    }
  },
  deepgramKey: "0f460db62b84c90bd958ff7a3d49436af615f7f4",
  logrocket: 'gzyfm5/mixer-if9v4'
};

/*
 * For easier debugging in development mode, you can import the following file  
 * to ignore   zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
